<template>
<v-data-table
   :headers="headers"
   :items="receptions">
</v-data-table>
</template>

<script>
export default {
name: "ReceptionControl",
  metaInfo: {
  title: 'Контроль ошибок при заполнении формы'
  },
  data() {
    return {
      receptions: [],
      headers: [{text: "Кто заполнял", value: "name"},
        {text: "id приёма", value: "id"},
        {text: "Замечание", value: "comment"}
      ]
    }
  }
}
</script>

<style scoped>

</style>
