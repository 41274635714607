<template>
  <v-container>
    <div class="text-center">
      <v-btn class="ma-2"
             outlined
             color="indigo"
             @click="retrieveStat('all')"
      >
        все
      </v-btn>
      <v-btn class="ma-2"
             outlined
             color="indigo"
             @click="retrieveStat('mo')"
      >
        МО
      </v-btn>
      <v-btn class="ma-2"
             outlined
             color="indigo"
             @click="retrieveStat('oiv')"
      >
        ОИВ
      </v-btn>

    </div>
    <div class="text-center">
    <v-progress-circular
          indeterminate
          color="red"
          v-if="loading"
      ></v-progress-circular>
    </div>
      <v-data-table
        class="row-pointer"
        v-if="!loading"
        :headers="headers"
        :items="unitsStat">
      <template v-slot:item.login_was="{ item }">
        <v-icon v-if="item.login_was">mdi-checkbox-marked</v-icon>
        <v-icon v-else>mdi-checkbox-blank</v-icon>
      </template>
      <template v-slot:item.name="{ item }">
        <a :href="item.link_to_phonebook" target="_blank">
          <v-icon>mdi-phone</v-icon>
        </a>
        {{ item.name }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "ReceptionStat",
  metaInfo: {
    title: 'Статистика по видеоприёмам'
  },
  data() {
    return {
      loading: true,
      unitsStat: [],
      headers: [{text: "Наименование", value: "name"},
        {text: "Добавлено приёмов (всего)", value: "value"},
        // {text: "Принято участие в приёмнах", value: "took_part_in_receptions"},
      ],
      headersFilled: false
    }
  },
  created() {
    this.retrieveStat()
  },
  methods: {
    async retrieveStat(retriveWho) {
      this.loading = true
      let url
      if (retriveWho === 'mo') {
        url = 'video_reception/api/reception_stat/mo'
      } else if (retriveWho === 'oiv') {
        url = 'video_reception/api/reception_stat/oiv'
      } else {
        url = 'video_reception/api/reception_stat/all'
      }

      await this.axios.get(url).then(response => this.unitsStat = response.data)
          .catch(err => console.log(err)).finally(() => this.loading = false)
      let firstUnitStat = this.unitsStat[0]
      for (const [m,] of Object.entries(firstUnitStat)) {
        if (m.startsWith('20') && !this.headersFilled) {
          this.headers.push({text: m.slice(0, 7), value: m})
        }
      }
      this.headersFilled = true
    }
  }
}
</script>

<style scoped>

</style>
