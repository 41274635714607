var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',{staticClass:"mb-5 text-center"},[_vm._v("Отчёты об отсутствии приёмов "),[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('v-list',_vm._l((_vm.excuseButtons),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.askAndAddExcuse(item.prepositional_month, new Date().getFullYear() + '-' + item.monthNumNorm + '-1')}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)]],2),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red"}}):_vm._e(),(!_vm.loading && this.excuses.length > 0)?_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.excuses},scopedSlots:_vm._u([{key:"item.period_of_excuse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.period_of_excuse.slice(0, 7))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.askAndDelete(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,865611035)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }