<template>
  <v-container>
      <v-img src="@/assets/schedule/1.png"></v-img>
      <v-img src="@/assets/schedule/2.png"></v-img>
      <v-img src="@/assets/schedule/3.png"></v-img>
  </v-container>
</template>

<script>
export default {
  name: "SstuGraphic",
  metaInfo: {
    title: 'График ОИВ ССТУ'
  },
}
</script>

<style scoped>

</style>
