import Vue from "vue";
import Router from "vue-router";
// import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import ReceptionAdd from "@/components/ReceptionAdd";
import {store} from "@/store";
import ReceptionList from "@/components/ReceptionList";
import ReceptionView from "@/components/ReceptionView";
import SstuGrapgic from "@/components/SstuGrapgic";
import UserProfile from "@/components/UserProfile";
import ExcusesList from "@/components/ExcusesList";
import ReceptionStat from "@/components/ReceptionStat";
import ReceptionControl from "@/components/ReceptionControl";
import ReceptionsByOrg from "@/components/ReceptionsByOrg";
import ForgotPassword from "@/components/ForgotPassword";
// import RegisterRequest from "@/components/RegisterRequest";


Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: {name: 'reception_list'}
        },
        {
            path: "/video_reception/reception/add",
            name: "reception_add",
            component: ReceptionAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/video_reception/reception/:id",
            name: "reception_view",
            component: ReceptionView,
            meta: {
                requiresAuth: true
            },
            props: true
        },


        {
            path: "/video_reception/receptions",
            name: "reception_list",
            component: ReceptionList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/login/",
            name: "login",
            component: Login,
            meta: {
                guest: true
            },
        },
        {
            path: "/video_reception/sstu_graphic",
            name: "sstu_graphic",
            component: SstuGrapgic,
            meta: {
                requiresAuth: true
            }

        },
        {
            path: "/profile",
            name: "profile",
            component: UserProfile,
            meta: {
                requiresAuth: true
            }

        },
        {
            path: "/video_reception/excuses",
            name: "excuses_list",
            component: ExcusesList,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: "/video_reception/stat",
            name: "reception_stat",
            component: ReceptionStat,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/video_reception/control",
            name: "reception_control",
            component: ReceptionControl,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/video_reception/receptions_by_org/",
            name: "receptions_by_org",
            component: ReceptionsByOrg,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '*',
            redirect: {name: 'reception_list'},
        },
        {
            path: "/forgot_password/",
            name: "forgot_password",
            component: ForgotPassword,
            meta: {
                guest: true
            },
        }
    ]
});


router.beforeEach((to, from, next) => {
        if (store.getters.isLoggedIn && to.name === 'login') {
            next({name: 'home'})
        }

        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                next()
                return
            }
            next('/login')
        } else {
            next()
        }
    }
)

export default router
