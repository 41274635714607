<template>
  <v-container>
    <template>
      <h2 class="mb-5"></h2>
      <validation-observer ref="form">
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                      v-slot="{ errors }"
                      name="дата приёма"
                      rules="required">
                    <v-text-field
                        hide-details="auto"
                        v-model="date_of_reception"
                        outlined
                        label="Дата приёма"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                    v-model="date_of_reception"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    выбрать
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="место нахождения заявителя"
                  rules="required">
                <v-autocomplete
                    hide-details="auto"
                    :items="addresses"
                    v-model="place_of_reception"
                    item-text="name"
                    item-value="id"
                    label="Место нахождения заявителя"
                    outlined
                    :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="орган, который провёл приём"
                  rules="required">
                <v-autocomplete
                    hide-details="auto"
                    v-model="connected_to"
                    :items="addresses"
                    :error-messages="errors"
                    item-text="name"
                    item-value="id"
                    label="Орган, который провёл приём"
                    outlined
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="результат приёма"
                  rules="required">
                <v-autocomplete
                    hide-details="auto"
                    :items="results"
                    :error-messages="errors"
                    label="Результат приёма"
                    v-model="result"
                    outlined
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="краткое содержание вопроса"
                  rules="required|min:10">
                <v-textarea
                    hide-details="auto"
                    :error-messages="errors"
                    outlined
                    auto-grow
                    label="Краткое содержание вопроса"
                    v-model="question_summary"
                    rows="1"
                ></v-textarea>
              </validation-provider>
            </v-col>

          </v-row>
          <v-row>
            <v-col>
              <template>
                <v-file-input
                    v-model="photos"
                    counter
                    label="Фото"
                    multiple
                    prepend-icon="mdi-camera"
                    outlined
                    accept="image/png, image/jpeg, image/bmp"
                    :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 2"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                    >
        и еще {{ photos.length - 2 }} фото
      </span>
                  </template>
                </v-file-input>
              </template>
            </v-col>
          </v-row>
          <v-btn @click="sendFormAndUploadPhotos" class="text-center mt-5" color="success">
            добавить
          </v-btn>
        </v-form>
      </validation-observer>
      <div class="mt-3" v-if="messages.success.length > 0">
        <v-alert v-bind:key="success" v-for="success in messages.success" type="success" width="50%" class="mx-auto">
          {{ success }}
        </v-alert>
      </div>

      <div class="mt-3" v-if="messages.error.length > 0">
        <v-alert v-bind:key="error" v-for="error in messages.error" type="error" width="50%" class="mx-auto">
          {{ error }}
        </v-alert>
      </div>

    </template>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "ReceptionAdd",
  metaInfo: {
    title: 'Добавить приём'
  },
  data() {
    return {
      place_of_reception: null,
      date_of_reception: null,
      connected_to: null,
      question_summary: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      addresses: [],
      items: [],
      selectedAddress: null,
      results: ['Разъяснено в ходе приёма', 'Принято письменное заявление',
        'Записан на личный приём к руководству', 'Меры приняты',
        'Приём не состоялся (организация не в сети)',
        'Приём не состоялся (УЛ не уполномочено давать разъяснения)',
        'Приём не состоялся (отказ от видеоприёма по инициативе УЛ)',
        'Приём не состоялся (другая причина)', 'Отказано'],
      result: null,
      valid: false,
      photos: null,
      messages: {success: [], error: []},
    }
  },
  created() {
    this.fillFieldValues()
    var debug = Vue.config.devtools
    if (debug) {
      this.date_of_reception = "2021-02-24"
      this.place_of_reception = 457
      this.connected_to = 457
      this.result = 'Приём не состоялся (организация не в сети)'
      this.question_summary = "Довольно краткое описание вопроса"

    }
  },

  methods: {
    clearInput() {
      this.date_of_reception = null
      this.place_of_reception = null
      this.connected_to = null
      this.result = null
      this.question_summary = null
      this.$refs.form.reset()
    },

    cleanMessages() {
      this.messages = {success: [], error: []}
    },
    async fillFieldValues() {
      this.axios.get('video_reception/api/units/', {useCache: true}).then(response => {
        this.addresses = response.data.results
      }).catch(error => {
        this.messages.error.push('Не удаётся загрузить значения для места приёма и исходящего соединения')
        console.log(error)
      })
    },
    async sendForm() {
      var data
      this.cleanMessages()


      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        data = {
          date_of_reception: this.date_of_reception,
          place_of_reception: this.place_of_reception,
          connected_to: this.connected_to,
          result: this.result,
          question_summary: this.question_summary,
          photos: this.photos
        }
        var formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
          formData.append(key, value);
        }
        return await this.axios.post('video_reception/api/receptions/', formData, {
          'headers': {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.messages.success.push('приём от ' + response.data['date_of_reception'] + ' добавлен успешно')
          // this.$router.push({name: 'reception_list'})
          return response.data.id
        }).catch(error => {
          this.messages.error.push(error.response.data.file[0])
        })
      }
    },
    async uploadPhoto(idOfReception, file) {
      let formData = new FormData();
      let data = {
        reception: idOfReception,
      };
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      formData.append('file', file)
      await this.axios.post('video_reception/api/reception_photos/', formData, {
        'headers': {
          'Content-Type': 'multipart/form-data'
        }
      }).catch(error => {
            this.photos = null
            this.messages.error.push(error.response.data.file[0])
            throw error
          }
      )
    },
    async uploadPhotos(photosList, idOfReception) {
      if (photosList) {
        for (let photo of photosList) {
          await this.uploadPhoto(idOfReception, photo)
        }
      }
    }
    ,
    async sendFormAndUploadPhotos() {
      if (this.place_of_reception === this.connected_to) {
        this.valid = false
        this.messages.error.push('Поле "Место нахождения заявителя" не может совпадать со значением поля "Орган, который провёл приём". Если приём проводили в Вашем ведомстве, то укажите ведомство в поле "Орган, который провёл приём"')
        return
      }
      this.idOfReception = await this.sendForm();
      if (this.idOfReception) {
        await this.uploadPhotos(this.photos, this.idOfReception).catch(() => {
          this.$router.push({
            name: "reception_view",
            params: {
              id: this.idOfReception,
              errorMessageOnLoad: 'Возникла ошибка при загрузке фото. Один из выбранных файлов, который вы пытались загрузить, поврежден или не является изображением.'
            }
          })
        })
      }
      if (this.messages.error.length === 0) {
        this.$refs.form.reset()
        await this.$router.push({name: 'reception_list'})
      }

    }
  }
}
</script>
