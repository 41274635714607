<template>
  <v-container>
    <template>
      <h2 class="mb-5">Редактирование приёма ID: {{ this.$route.params.id }}</h2>
      <v-progress-circular
          indeterminate
          color="red"
          v-if="loading"
      ></v-progress-circular>
      <validation-observer ref="form" v-if="!loading && !errorOnLoad">
        <v-form ref="form">
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                      v-slot="{ errors }"
                      name="дата приёма"
                      rules="required">
                    <v-text-field
                        hide-details="auto"
                        v-model="date_of_reception"
                        outlined
                        label="Дата приёма"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                    v-model="date_of_reception"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(date)"
                  >
                    выбрать
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="место приёма"
                  rules="required">
                <v-autocomplete
                    hide-details="auto"
                    :items="addresses"
                    v-model="place_of_reception"
                    item-text="name"
                    item-value="id"
                    label="Место приёма"
                    outlined
                    :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="орган, который провёл приём"
                  rules="required">
                <v-autocomplete
                    hide-details="auto"
                    v-model="connected_to"
                    :items="addresses"
                    :error-messages="errors"
                    item-text="name"
                    item-value="id"
                    label="Орган, который провёл приём"
                    outlined
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="результат приёма"
                  rules="required">
                <v-select
                    hide-details="auto"
                    :items="results"
                    :error-messages="errors"
                    label="Результат приёма"
                    v-model="result"
                    outlined
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                  v-slot="{ errors }"
                  name="краткое содержание вопроса"
                  rules="required|min:10">
                <v-textarea
                    hide-details="auto"
                    :error-messages="errors"
                    outlined
                    auto-grow
                    label="Краткое содержание вопроса"
                    v-model="question_summary"
                    rows="1"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row v-if="photos.length > 0">
            <v-col>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Фотографии
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="mb-3" v-for="photo in photos"
                            :key="photo.id">
                      <v-img :src="photo.file"></v-img>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-chip class="mr-3" label outlined v-if="photo.markedForDelete">будет удалено</v-chip>
                        <v-btn @click="toggleDeleteImage(photo.id)">
                          <v-icon v-if="!photo.markedForDelete" color="error">mdi-delete</v-icon>
                          <v-icon v-else color="success">mdi-delete-forever-outline</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <template>
                <v-file-input
                    v-model="photosToAdd"
                    counter
                    label="Фото"
                    multiple
                    prepend-icon="mdi-camera"
                    outlined
                    accept="image/png, image/jpeg, image/bmp"
                    :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 2"
                        dark
                        label
                        small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                    >
        и еще {{ photosToAdd.length - 2 }} фото
      </span>
                  </template>
                </v-file-input>
              </template>
            </v-col>
          </v-row>

          <div class="text-left">
            <v-btn @click="sendForm" class="mt-5 mr-5 text-center" color="success">
              сохранить и закрыть
            </v-btn>
            <v-btn @click="$router.go(-1)" class="mt-5 mr-5 text-center" color="warning">
              закрыть
            </v-btn>
            <v-btn @click="deleteAndGoToList" class="mt-5 text-center" color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
      <div class="mt-3" v-if="errorMessageOnLoad">
        <v-alert prominent v-bind:key="error" v-model="errorMessageOnLoad" type="error" width="50%" class="mx-auto">
          <v-row>
            <v-col class="grow">{{ errorMessageOnLoad }}</v-col>
          </v-row>
        </v-alert>
      </div>


      <div class="mt-3" v-if="messages.success.length > 0">
        <v-alert v-bind:key="success" v-for="success in messages.success" type="success" width="50%" class="mx-auto">
          {{ success }}
        </v-alert>
      </div>

      <div class="mt-3" v-if="messages.error.length > 0">
        <v-alert prominent v-bind:key="error" v-for="error in messages.error" type="error" width="50%" class="mx-auto">
          <v-row>
            <v-col class="grow">{{ error }}</v-col>
            <v-col class="shrink" @click="$router.back()">
              <v-btn>назад</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </template>
  </v-container>
</template>

<script>
export default {
  name: "ReceptionAdd",
  metaInfo: {
    title: 'Приём'
  },
  props: {
    errorMessageOnLoad: String,
  },

  data() {
    return {
      place_of_reception: null,
      date_of_reception: null,
      connected_to: null,
      question_summary: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      addresses: [],
      items: [],
      selectedAddress: null,
      results: ['Разъяснено в ходе приёма', 'Принято письменное заявление',
        'Записан на личный приём к руководству', 'Меры приняты',
        'Приём не состоялся (организация не в сети)',
        'Приём не состоялся (УЛ не уполномочено давать разъяснения)',
        'Приём не состоялся (отказ от видеоприёма по инициативе УЛ)',
        'Приём не состоялся (другая причина)', 'Отказано'],
      result: null,
      valid: false,
      messages: {success: [], error: []},
      loading: true,
      errorOnLoad: false,
      photos: null,
      photosToAdd: null
    }
  },
  created() {
    this.fillFieldValuesAndGetObject()
  },
  methods: {
    cleanMessages() {
      this.messages = {success: [], error: []}
    },
    async fillFieldValues() {
      this.axios.get('video_reception/api/units/', {useCache: true}).then(response => {
        this.addresses = response.data.results
      }).catch(error => {
        this.messages.error.push('Не удаётся загрузить значения для места приёма и исходящего соединения')
        console.log(error)
      })
    },
    async fillFieldValuesAndGetObject() {
      this.loading = true
      await this.fillFieldValues()
      await this.axios.get('video_reception/api/receptions/' + this.$route.params.id + '/').then(response => {
            let data;
            data = response.data
            this.date_of_reception = data.date_of_reception
            this.place_of_reception = data.place_of_reception
            this.connected_to = data.connected_to
            if (!this.results.includes(data.result)) {
              this.results.unshift(data.result)
            }
            this.result = data.result
            this.question_summary = data.question_summary
            this.photos = data.photos
            this.photos.forEach((obj) => obj['markedForDelete'] = false)
          }
      ).catch(error => {
        console.log(error)
        if (error.response.status === 403) {
          this.messages.error.push('Недостаточно прав')
        } else {
          this.messages.error.push('Не удаётся загрузить документ')
          this.messages.error.push(error)
        }
        this.errorOnLoad = true
      })
      this.loading = false
    },
    async uploadPhoto(idOfReception, file) {
      let formData = new FormData();
      let data = {
        reception: idOfReception,
      };
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }
      formData.append('file', file)
      await this.axios.post('video_reception/api/reception_photos/', formData, {
        'headers': {
          'Content-Type': 'multipart/form-data'
        }
      }).catch(error => {
        this.photosToAdd = null
        this.messages.error.push(error.response.data.file[0])
        throw error
      })
    },
    async uploadPhotos(photosList, idOfReception) {
      if (photosList) {
        for (let photo of photosList) {
          await this.uploadPhoto(idOfReception, photo)
        }
      }
    },
    async sendForm() {
      var data
      if (this.place_of_reception === this.connected_to) {
        this.valid = false
        this.messages.error.push('Поле "Место нахождения заявителя" не может совпадать со значением поля "Орган, который провёл приём". Если приём проводили в Вашем ведомстве, то укажите ведомство в поле "Орган, который провёл приём"')
        return
      }

      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        this.cleanMessages()
        data = {
          date_of_reception: this.date_of_reception,
          place_of_reception: this.place_of_reception,
          connected_to: this.connected_to,
          result: this.result,
          question_summary: this.question_summary,
        }
        await this.axios.patch('video_reception/api/receptions/' + this.$route.params.id + '.json', data).then(response => {
          this.messages.success.push('приём от ' + response.data['date_of_reception'] + ' сохранен успешно')

        }).catch(error => {
          this.messages.error.push(error.response.data.details)
        })
      }
      let deleteThisImages = this.photos.filter((obj) => obj.markedForDelete === true).map((obj) => obj.id)
      await deleteThisImages.forEach((imageId) => {
        this.axios.delete('video_reception/api/reception_photos/' + imageId + '/')
      })
      await this.uploadPhotos(this.photosToAdd, this.$route.params.id)
      if (this.messages.error.length === 0) {
        await this.$router.push({name: 'reception_list'})
      }
    },
    async deleteItem() {
        await this.axios.delete('video_reception/api/receptions/' + this.$route.params.id + '/').then(() => {
          this.$router.push({name: 'reception_list'})
        }).catch(error => {
          this.messages.error.push(error.response.data.detail)})
    },
    async deleteAndGoToList() {
      if (confirm('Вы точно хотите удалить приём ID: ' + this.$route.params.id)) {
        await this.deleteItem()
      }

    },
    toggleDeleteImage(idOfImage) {
      let photoObjectIndex = this.photos.findIndex((obj) => obj.id === idOfImage)
      let origPhotoObject = this.photos[photoObjectIndex]
      origPhotoObject['markedForDelete'] = origPhotoObject['markedForDelete'] !== true;
      this.$set(this.photos, photoObjectIndex, origPhotoObject)
    }
  },
}
</script>
