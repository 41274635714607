<template>
  <v-app id="inspire">
    <v-app-bar
        app
        color="green"
        flat
    >
      <v-avatar style="cursor: pointer" class="mx-auto">
        <img src="./assets/dvp23 logo white.svg" height="50"
             @click="$router.push('/')">
      </v-avatar>
      <v-tabs
          v-if="isLoggedIn"
          color="white"
          background-color="green"
      >
        <v-tab
            v-for="link in links"
            :key="link.name"
            text
            @click="$router.push({'name': link.to})"
        >
          {{ link.name }}
        </v-tab>
      </v-tabs>
      <h3 v-if="isLoggedIn" style="color: white">dvp23.ru</h3>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12"
                 sm="3"
                 v-if="isLoggedIn"
                 class="d-print-none"
          >
            <v-card
                rounded="lg"
                class="mx-auto"
            >
              <v-card-subtitle>Видеоприёмы</v-card-subtitle>
              <v-list>
                <v-list-item-group :value="model">
                  <v-list-item
                      @click="$router.push({name: item.to})"
                      v-for="(item, i) in menuItems"
                      :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12"
                 sm="6"
                 class="mx-auto">
            <v-sheet
                rounded="lg"
            >
              <v-card min-height="80vh">
                <router-view v-cloak></router-view>
              </v-card>
            </v-sheet>
          </v-col>
          <v-col cols="12"
                 sm="3"
                 v-if="isLoggedIn"
                 class="d-print-none">
            <v-card
            >
              <v-card-subtitle>Профиль</v-card-subtitle>
              <v-card-text>Пользователь: {{ $store.state.user.username }}<br>
                Организация: {{ $store.state.unit_name }}
              </v-card-text>

              <v-card-actions>
                <v-btn block elevation="0" @click="logout">выйти</v-btn>
              </v-card-actions>
            </v-card>
            <!--            <v-btn @click.prevent='downloadItem({url: "/media/7b57b16c-4c47-44f7-8b15-a6f6068187b6/Инструкция ДВП23 видеоприёмы 08.02.2021.docx", label: "Инструкция ДВП23 видеоприёмы 08.02.2021.docx"})' block-->
            <!--                   class="mt-3 disabled">Инструкция-->
            <!--            </v-btn>-->
          </v-col>
        </v-row>
        <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="primary"
            @click="toTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import {cacheAdapterEnhancer} from "axios-extensions";

function getDateTodayJson() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd
  return today
}

function getDateTodayFormatted() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = dd + '.' + mm + '.' + +yyyy;
  return today
}

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '',
    // all titles will be injected into this template
    titleTemplate: '%s | ДВП23'
  },
  data() {
    return {
      toggle_exclusive: undefined,
      links: [
        {name: 'Видеоприёмы', to: 'reception_list'},
      ],
      date_json: getDateTodayJson(),
      date_local: getDateTodayFormatted(),
      menuItems: [
        {text: 'Список', icon: 'mdi-account-box-multiple', to: 'reception_list'},
        {text: 'Новый приём', icon: 'mdi-comment-plus', to: 'reception_add'},
        {text: 'Отчёты об отсутствии', icon: 'mdi-timer-sand-empty', to: 'excuses_list'},
        {text: 'График ОИВ ССТУ', icon: 'mdi-clock', to: 'sstu_graphic'},
      ],
      fab: false,
    }
  },
  created: function () {
    var debug = Vue.config.devtools
    this.axios.defaults.baseURL = debug ? 'http://127.0.0.1:8000' : 'https://api.dvp23.ru/'
    var localToken = localStorage.getItem('token')
    this.axios.defaults.headers.common['Authorization'] = localToken ? 'Token ' + localToken : ''
    this.axios.defaults.adapter = cacheAdapterEnhancer(this.axios.defaults.adapter, {
      enabledByDefault: false,
      cacheFlag: 'useCache'
    })
    if (this.isStaff === "true") {
      this.menuItems.push({text: 'Статистика', icon: 'mdi-calculator-variant', to: 'reception_stat'})
      this.menuItems.push({text: 'Поиск по организациии', icon: 'mdi-feature-search', to: 'receptions_by_org'})
    }

    this.axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if ((err.status === 401 && err.config && !err.config.__isRetryRequest) || err.status === 403) {
          this.$store.dispatch('logout')
        }
        throw err;
      });
    })
  },

  methods: {
    logout: function () {
      this.$store.dispatch('logout')
          .then(() => {
            this.$router.push('/login')
          })
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    downloadItem({url, label}) {
      this.axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/msword'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn
    },
    isStaff: function () {
      return this.$store.getters.isStaff
    },
    model: function () {
      return this.menuItems.map(function (e) {
        return e.to
      }).indexOf(this.$route.name)
    },
  }
}


</script>

<style lang="sass">
@import '../node_modules/@mdi/font/css/materialdesignicons.min.css'
</style>

<style>
[v-cloak] {
  display: none;
}
</style>

