<template>
<p>Выполнен вход: {{ $store.state.user.username }}</p>
</template>

<script>
export default {
name: "UserProfile"
}
</script>

<style scoped>

</style>
