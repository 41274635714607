var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[[_c('h2',{staticClass:"mb-5"}),_c('validation-observer',{ref:"form"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"дата приёма","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"auto","outlined":"","label":"Дата приёма","readonly":"","required":"","error-messages":errors},model:{value:(_vm.date_of_reception),callback:function ($$v) {_vm.date_of_reception=$$v},expression:"date_of_reception"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date_of_reception),callback:function ($$v) {_vm.date_of_reception=$$v},expression:"date_of_reception"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" выбрать ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"место нахождения заявителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"hide-details":"auto","items":_vm.addresses,"item-text":"name","item-value":"id","label":"Место нахождения заявителя","outlined":"","error-messages":errors},model:{value:(_vm.place_of_reception),callback:function ($$v) {_vm.place_of_reception=$$v},expression:"place_of_reception"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"орган, который провёл приём","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"hide-details":"auto","items":_vm.addresses,"error-messages":errors,"item-text":"name","item-value":"id","label":"Орган, который провёл приём","outlined":""},model:{value:(_vm.connected_to),callback:function ($$v) {_vm.connected_to=$$v},expression:"connected_to"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"результат приёма","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"hide-details":"auto","items":_vm.results,"error-messages":errors,"label":"Результат приёма","outlined":""},model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"краткое содержание вопроса","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"hide-details":"auto","error-messages":errors,"outlined":"","auto-grow":"","label":"Краткое содержание вопроса","rows":"1"},model:{value:(_vm.question_summary),callback:function ($$v) {_vm.question_summary=$$v},expression:"question_summary"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[[_c('v-file-input',{attrs:{"counter":"","label":"Фото","multiple":"","prepend-icon":"mdi-camera","outlined":"","accept":"image/png, image/jpeg, image/bmp","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v(" и еще "+_vm._s(_vm.photos.length - 2)+" фото ")]):_vm._e()]}}]),model:{value:(_vm.photos),callback:function ($$v) {_vm.photos=$$v},expression:"photos"}})]],2)],1),_c('v-btn',{staticClass:"text-center mt-5",attrs:{"color":"success"},on:{"click":_vm.sendFormAndUploadPhotos}},[_vm._v(" добавить ")])],1)],1),(_vm.messages.success.length > 0)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.messages.success),function(success){return _c('v-alert',{key:success,staticClass:"mx-auto",attrs:{"type":"success","width":"50%"}},[_vm._v(" "+_vm._s(success)+" ")])}),1):_vm._e(),(_vm.messages.error.length > 0)?_c('div',{staticClass:"mt-3"},_vm._l((_vm.messages.error),function(error){return _c('v-alert',{key:error,staticClass:"mx-auto",attrs:{"type":"error","width":"50%"}},[_vm._v(" "+_vm._s(error)+" ")])}),1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }