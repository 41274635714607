import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {username: localStorage.getItem('user')},
        unit_id: localStorage.getItem('unit_id') || '',
        unit_name: localStorage.getItem('unit_name') || '',
        is_staff: localStorage.getItem('is_staff') || '',
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, data) {
            state.status = 'success'
            state.token = data.token
            state.user = {username: data.user}
            state.unit_id = data.unit_id
            state.unit_name = data.unit_name
            state.is_staff = data.is_staff
        },
        auth_error(state) {
            state.status = 'error'
            state.token = ''
            state.user = {}
            state.unit_id = ''
            state.unit_name = ''
            state.is_staff = ''
        },
        logout(state) {
            state.status = ''
            state.token = ''
            state.unit_id = ''
            state.unit_name = ''
            state.is_staff = ''
        },
    },
    actions: {
        async login({commit}, data) {
            return await new Promise((resolve, reject) => {
                commit('auth_request')
                Vue.axios.post('custom-auth/', data)
                    .then(resp => {
                        const token = resp.data['auth_token']
                        localStorage.setItem('token', token)
                        Vue.axios.defaults.headers.common['Authorization'] = 'Token ' + token
                        localStorage.setItem('user', data.username)
                        localStorage.setItem('unit_id', resp.data.unit_id)
                        localStorage.setItem('unit_name', resp.data.unit_name)
                        localStorage.setItem('is_staff', resp.data.is_staff)
                        commit('auth_success', {
                            user: data.username,
                            token: token,
                            unit_id: resp.data.user_id,
                            unit_name: resp.data.unit_name,
                            is_staff: resp.data.is_staff
                        })
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                localStorage.removeItem('unit_id')
                localStorage.removeItem('unit_name')
                localStorage.removeItem('is_staff')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isStaff: state => state.is_staff,
    }
})

