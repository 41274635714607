<template>
  <v-container>
    <h2 class="mb-5 text-center">Отчёты об отсутствии приёмов
<template>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="red"
                  outlined
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  @click="askAndAddExcuse(item.prepositional_month, new Date().getFullYear() + '-' + item.monthNumNorm + '-1')"
                  v-for="(item, index) in excuseButtons"
                  :key="index"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </h2>
    <v-progress-circular
        indeterminate
        color="red"
        v-if="loading"
    ></v-progress-circular>
    <v-data-table
        class="row-pointer"
        v-if="!loading && this.excuses.length > 0"
        :headers="headers"
        :items="excuses">
      <template v-slot:item.period_of_excuse="{ item }">
        {{ item.period_of_excuse.slice(0, 7) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            @click="askAndDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
let allMonths = [
  ['январь', 'январе'],
  ['февраль', 'феврале'],
  ['март', 'марте'],
  ['апрель', 'апреле'],
  ['май', 'мае'],
  ['июнь', 'июне'],
  ['июль', 'июле'],
  ['август', 'августе'],
  ['сентябрь', 'сентябре'],
  ['октябрь', 'октябре'],
  ['ноябрь', 'ноябре'],
  ['декабрь', 'декабре']]

function firstDayInPreviousMonth(yourDate) {
  return new Date(yourDate.getFullYear(), yourDate.getMonth() - 1, 1);
}


Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
  ].join('-');
};


export default {
  name: "ExcusesList",
  metaInfo: {
    title: 'Отчёты об отсутствии приёмов'
  },
  data() {
    return {
      excuses: [],
      loading: true,
      headers: [{text: "", value: "period_of_excuse"},
        {text: "", value: "unit_name"},
        {text: "", value: "actions"}],

      excuseButtons: [],
    }
  },
  created() {
    let monthNumNorm = 1;
    allMonths.slice(0, new Date().getMonth() + 1).forEach((month) => {
      this.excuseButtons.push({
        'title': 'в ' + month[1] + ' приёмов не было',
        'prepositional_month': month[1],
        'monthNumNorm': monthNumNorm
      })
      monthNumNorm++
    })
    this.retrieveExcuses()
  },
  methods: {
    retrieveExcuses() {
      this.loading = true
      this.axios.get('video_reception/api/excuses/').then(response => {
        this.excuses = response.data.results
      }).catch(error => console.log(error)).finally(() => {
        this.loading = false
      })
    },
    askAndAddExcuse(monthName, monthNumNorm = firstDayInPreviousMonth(new Date).yyyymmdd()) {
      if (confirm('Вы точно хотите добавить отчёт об отсутствии видеоприёмов в ' + monthName + '?')) {
        this.axios.post('video_reception/api/excuses/',
            {period_of_excuse: monthNumNorm})
            .then(response => this.excuses.push(response.data))
      }
    },
    askAndDelete(e) {
      if (confirm('Вы точно хотите удалить отчёт об отсутствии видеоприёмов ' + e.period_of_excuse + '?')) {
        this.axios.delete('video_reception/api/excuses/' + e.id + '/')
            .then(() => {
              this.excuses = this.excuses.filter(obj => obj.id !== e.id)
            })
      }
    },
    handleClick(e) {
      this.askAndDelete(e)
    }
  }
}
</script>
