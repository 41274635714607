import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {store} from "@/store";

import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import {messages} from 'vee-validate/dist/locale/ru.json';
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'


import VueFileAgent from 'vue-file-agent';

// import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});


Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(VueMeta)
Vue.use(VueFileAgent)
Vue.use(VueCookies)

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
}).$mount('#app')

