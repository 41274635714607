<template>
  <v-container>
    <div class="pa-0" style="max-height:100vh;overflow:hidden;">
      <v-spacer style="height: 100px"></v-spacer>
      <v-card class="mx-auto elevation-5 text-center pa-5" max-width="350"
              style="border-radius:20px;position:relative;top:-50px;"
      >
        <v-card-subtitle class="title black--text pa-1">
          Забыли пароль?
        </v-card-subtitle>
        <v-card-subtitle class="pa-1">
          Введите свой e-mail адрес ниже, и мы вышлем вам письмо для сброса пароля.
        </v-card-subtitle>
        <validation-observer ref="form">
          <v-form
              ref="form"
              v-model="valid"
          >
            <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required">
              <v-text-field v-model="email" autocomplete="autocomplete"
                            label="e-mail" required :error-messages="errors" v-on:keyup.enter="login"></v-text-field>
            </validation-provider>
            <h1 class="red--text subtitle-2">{{ message }}</h1>
          </v-form>
        </validation-observer>
        <v-card-actions>
          <v-btn
              :loading="loading"
              :disabled="loading"
              @click="send"
              color="red" dark block rounded
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>

    </div>
  </v-container>

</template>

<script>
// function getCookie(name) {
//   let cookieValue = null;
//   if (document.cookie && document.cookie !== '') {
//     const cookies = document.cookie.split(';');
//     for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i].trim();
//       // Does this cookie string begin with the name we want?
//       if (cookie.substring(0, name.length + 1) === (name + '=')) {
//         cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//         break;
//       }
//     }
//   }
//   return cookieValue;
// }

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: 'Забыли пароль?'
  },
  data() {
    return {
      email: "",
      loading: false,
      message: "",
      valid: false,
    }
  },
  methods: {
    async send() {
      this.valid = await this.$refs.form.validate()
      if (this.valid) {
        let email = this.email
        this.axios.post('/accounts/password/reset/', {'email': email})
            .then(resp => {
              console.log(resp)
            })
            .catch(err => {
              this.message = 'Ошибка, обновите страницу и попробуйте ещё раз'
              this.email = ""
              console.log(err)
            })
      }
    },
    async getCsrfToken() {
      try {
        const response = await this.axios.get('/get_csrf_token/');
        this.$cookies.set("X-CSRF-TOKEN", response.data['csrftoken'])
        console.log(response.data['csrftoken'])
      } catch (error) {
        console.error(error)
      }
    }
  }
  ,
  created() {
    this.getCsrfToken()
  }
}

</script>

<style scoped>

</style>
