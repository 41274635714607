<template>
  <v-container>
    <h2 class="text-center">Поиск по организации</h2>
    <div class="mt-3" v-if="messages.length > 0">
      <v-alert v-bind:key="error" v-for="error in messages" type="error" width="50%" class="mx-auto">
        {{ error }}
      </v-alert>
    </div>
    <v-autocomplete
        hide-details="auto"
        :items="units"
        v-model="unitSelected"
        item-text="name"
        item-value="id"
        label="Организация"
        outlined
    ></v-autocomplete>
    <v-container fluid>
      <v-row dense>
        <v-col v-bind:key="period.period" v-for="period in data_by_month" cols="4">
          <v-hover v-slot="{ hover }">
            <v-card
                height="100%"
                outlined
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
            >
              <v-card-title>{{ period.period }}</v-card-title>
              <v-card-text v-if="period['added_receptions'].length === 0 && period.excuses.length === 0"><span
                  class="red--text">нет данных</span></v-card-text>
              <v-card-text
                  v-if="period['added_receptions'].length !== 0">Добавлено приёмов: {{ period['added_receptions'].length }}
              </v-card-text>
              <v-card-actions v-if="period['added_receptions'].length !== 0">
                <v-btn block @click="showInTable(period['added_receptions'])">просмотр
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-if="receptions.length > 0">
        <v-col>
          <v-data-table
              class="row-pointer"
              :headers="headers"
              :items="receptions"
              @click:row="handleClick">
            <template v-slot:item.date_of_reception="{ item }">
              <span class="text-no-wrap">{{ reformatDate(item.date_of_reception) }}</span>
            </template>
            <template v-slot:item.reception_info="{ item }">
        <span>{{ item.question_summary }}<br>
          <span v-if="item.photos.length > 0"><v-icon>mdi-camera</v-icon></span><br v-if="item.photos.length > 0">
          <span class="text--secondary"><v-icon>mdi-account</v-icon> {{ item.place_of_reception_name }}<br>
        <v-icon>mdi-link</v-icon> {{ item.connected_to_name }}</span>
          </span>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "ReceptionsByOrg",
  metaInfo: {
    title: 'Поиск по организации'
  },
  data() {
    return {
      units: [],
      unitSelected: null,
      messages: [],
      data_by_month: null,
      receptions: [],
      headers: [{text: "ID", value: "id"},
        {text: "Дата приёма", value: "date_of_reception"},
        {text: 'Основная информация', value: "reception_info"}],
    }
  },
  methods: {
    showInTable(receptions) {
      this.receptions = receptions
    },
    handleClick(e) {
      this.$router.push('/video_reception/reception/' + e.id)
    },
    reformatDate(dateString) {
      var dateStringSplit = dateString.split('-')
      return dateStringSplit[2] + '.' + dateStringSplit[1] + '.' + dateStringSplit[0]
    },
  },
  created() {
    this.axios.get('video_reception/api/units?main_municipality=true&main_executive=', {useCache: true}).then(response => {
      this.units = response.data.results
    }).catch(error => {
      this.messages.error.push('Не удаётся загрузить список организаций')
      console.log(error)
    })

    this.axios.get('video_reception/api/units?main_municipality=&main_executive=true', {useCache: true}).then(response => {
      this.units = this.units.concat(response.data.results)
    }).catch(error => {
      this.messages.error.push('Не удаётся загрузить список организаций')
      console.log(error)
    })

  },
  watch: {
    unitSelected: function (val) {
      this.receptions = []
      this.axios.get('video_reception/api/receptions_by_org/' + val + '/', {useCache: true}).then(response => {
        this.data_by_month = response.data['data_by_month']

      }).catch(error => {
        console.log(error)
        this.messages.push('Не удалось загрузить информацию об организации')
      })
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
