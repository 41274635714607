<template>
  <v-container>
    <div class="text-center mb-5">
      <h2 class="">Список приёмов</h2>
      <h3 v-if="selectedMonth">{{ selectedMonth }}</h3>
      <div>
        <v-btn v-bind:key="month" v-for="month in months"
               class="ma-2"
               outlined
               color="indigo"
               @click="retrieveReceptions(month)"
        >
          {{ month }}
        </v-btn>
      </div>
    </div>
    <div class="text-center">
      <v-progress-circular
          indeterminate
          color="red"
          v-if="loading"
      ></v-progress-circular>
    </div>
    <v-data-table
        class="row-pointer"
        v-if="!loading"
        :headers="headers"
        :items="receptions"
        @click:row="handleClick">
      <template v-slot:item.date_of_reception="{ item }">
        <span class="text-no-wrap">{{ reformatDate(item.date_of_reception) }}</span>
      </template>
      <template v-slot:item.reception_info="{ item }">
        <span>{{ item.question_summary }}<br>
          <span v-if="item.photos.length > 0"><v-icon>mdi-camera</v-icon></span><br v-if="item.photos.length > 0">
          <span class="text--secondary"><v-icon>mdi-account</v-icon> {{ item.place_of_reception_name }}<br>
        <v-icon>mdi-link</v-icon> {{ item.connected_to_name }}</span>
          </span>
      </template>

    </v-data-table>
    <div class="mt-3" v-if="messages.success.length > 0">
      <v-alert v-bind:key="success" v-for="success in messages.success" type="success" width="50%" class="mx-auto">
        {{ success }}
      </v-alert>
    </div>

    <div class="mt-3" v-if="messages.error.length > 0">
      <v-alert v-bind:key="error" v-for="error in messages.error" type="error" width="50%" class="mx-auto">
        {{ error }}
      </v-alert>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ReceptionList",
  metaInfo: {
    title: 'Список приёмов'
  },
  data() {
    return {
      receptions: [],
      loading: true,
      headers: [{text: "ID", value: "id"},
        {text: "Дата приёма", value: "date_of_reception"},
        {text: 'Основная информация', value: "reception_info"}],
      messages: {success: [], error: []},
      filter: [],
      selectedMonth: null
    }
  },
  created() {
    this.retrieveReceptions()
  },
  methods: {
    cleanMessages() {
      this.messages = {success: [], error: []}
    },
    retrieveReceptions(month = null) {
      this.cleanMessages()
      this.loading = true
      let url = 'video_reception/api/receptions/'
      if (month) {
        let allMonths = ['январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь']
        this.selectedMonth = month
        month = allMonths.indexOf(month)
        let today = new Date()
        let lastDay = new Date(today.getFullYear(), month + 1, 0);
        let firstDay = new Date(today.getFullYear(), lastDay.getMonth(), 1);
        url += '?date_of_reception__gte=' + firstDay.getFullYear() + '-' + (firstDay.getMonth() + 1) + '-' + firstDay.getDate()
        url += '&date_of_reception__lte=' + lastDay.getFullYear() + '-' + (lastDay.getMonth() + 1) + '-' + lastDay.getDate()
      }
      this.axios.get(url).then(response => {
        this.receptions = response.data.results
      }).catch(error => {
        console.log(error)
        this.messages.error.push('Не удаётся загрузить список видеоприёмов')
      }).finally(() => {
        this.loading = false
      })
    },
    handleClick(e) {
      this.$router.push('/video_reception/reception/' + e.id)
    },
    reformatDate(dateString) {
      var dateStringSplit = dateString.split('-')
      return dateStringSplit[2] + '.' + dateStringSplit[1] + '.' + dateStringSplit[0]
    },
    addToFilter(obj) {
      this.filter.push(obj)
    }

  },
  computed: {
    months: () => {
      return [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь'
      ].slice(0, new Date().getMonth() + 1)
    }
  },
  watch: {
    filter: function () {
      if (this.filter['photo']) {
        this.receptions = this.receptions.filter(obj => obj.photo.length > 0)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
